@import "src/styles/vars.scss";

.road {
  fill: $gray;
  stroke: $grayText;
}

.text {
  fill: $grayText;
}

.pin {
  fill: $primary;
  stroke: $white;
}

.pinLabel {
  fill: $primary;
}
