html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
