@import "src/styles/vars.scss";

@mixin white-gradient {
  background: linear-gradient(to right, $primary 0%, #ffffff00 100%);
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-380px * 5));
  }
}

// Styling
.slider {
  height: 380px;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 380px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slideTrack {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(380px * 5);
  }

  .slide {
    height: 380px;
    width: 380px;

    img {
      width: calc(380px - $vSpace);
      height: calc(380px - $vSpace);
      border-radius: 10px;
      margin: calc($vSpace / 2);
    }
  }
}

@media only screen and (max-width: 500px) {
  .slider {
    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 380px;
      position: absolute;
      width: 50px;
      z-index: 2;
    }
  }
}
