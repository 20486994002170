// $primary: #a3467b;
$primary: #2c786c;
$primary25: #2c786d41;
$primary75: #2c786db6;
// $primary: #7060a7;
$grayText: #76797a;
$gray: #f2f3f7;
$white: #ffffff;
$black: #000000;
$red: #a70808;

$borderLight: #e6e8ed;

$fontFamilyHeaders: "Montserrat", sans-serif;
$fontFamilyPrimary: "Nunito", sans-serif;

$headerWeights: (
  "light": 300,
  "regular": 400,
  "medium": 500,
  "bold": 700,
);

$headerSizes: (
  "small": 16px,
  "regular": 18px,
  "large": 20px,
  "x-large": 22px,
);

$vSpace: 18px;
$pageMargin: 30px;

@mixin pStyles {
  font-size: 18px;
  line-height: 1.6;
}
