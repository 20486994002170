@import "src/styles/vars.scss";

.hero {
  background-image: url("../assets/hero.jpg");
  padding: $pageMargin;
  border-radius: 15px;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: $white;
  display: flex;
  align-items: flex-end;
}

@media only screen and (min-width: 1400px) {
  .hero {
    height: 550px;
  }
}

.heroText {
  max-width: fit-content;
  max-height: fit-content;
  background-color: $primary75;
  backdrop-filter: blur(10px);
  color: $white;
  border-radius: 10px;
  padding: $vSpace;
}

@media only screen and (max-width: 700px) {
  .heroText {
    background-color: $primary75;
    // backdrop-filter: none;
    transform: translateY(6 * $vSpace);

    .heroTitle {
      font-size: 35px;
    }

    .heroDetail {
      font-size: 18px;
    }
  }
}

.h1 {
  font-size: 50px;
  font-weight: 600;
  font-family: $fontFamilyHeaders;
  margin: 0;
  margin-bottom: 10px;
}

.h2 {
  font-size: 40px;
  font-weight: 600;
  font-family: $fontFamilyHeaders;
  margin: 0;
  margin-bottom: 10px;
}

.h3 {
  font-size: 30px;
  font-weight: 500;
  font-family: $fontFamilyHeaders;
  margin: 0;
  margin-bottom: 10px;
}

.h4 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.serviceTag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: $vSpace * 2;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #00000059;
}

.serviceTagTitle {
  text-align: center;
}

@media only screen and (max-width: 550px) {
  .serviceTagTitle {
    font-size: 26px;
  }
}

.photo {
  border-radius: 10px;
  max-width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.colJustifyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.colAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $vSpace * 1.5;

  & > * {
    width: 100%;
  }
}

.learnMore {
  color: $primary;
  margin-top: calc($vSpace / 3);
}

.map {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
  }

  svg {
    width: 450px;
    max-width: calc(100% - (2 * $pageMargin));
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0 2px 4px #0000004d);
      transform: translateY(-8px);
    }
  }
}

.locationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table {
  th {
    padding-right: $pageMargin;
  }
}

.contactTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

.contactDetail {
  font-size: 16px;
  margin-top: calc($vSpace / 2);
}

.contactLink {
  text-decoration: none;
  color: $primary;
  padding-bottom: 3px;
  border-bottom: 1.5px solid $primary;
  margin-bottom: calc($vSpace / 2);
}

.contactUsDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    max-width: 710px;
    @include pStyles;
  }
}

.homeTextWrapper,
.servicesTextWrapper,
.aboutTextWrapper {
  b,
  li,
  p {
    @include pStyles;
  }
}

.testimonialsWrapper {
  color: $white;
}

.quote {
  position: relative;

  &::after {
    position: absolute;
    top: -45px;
    right: 10px;
    content: '"';
    color: $gray;
    font-size: 135px;
    font-style: italic;
  }
}

.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial {
  background-color: $white;
  padding: calc($vSpace / 2) $vSpace * 1.5;
  margin: calc($pageMargin / 2);
  transform: translateX(-$pageMargin / 2);
  max-width: 650px;
  border-radius: 10px;
  opacity: 100%;
  transition: opacity 600ms ease;
  position: absolute;
}

.testimonialLarge {
  position: static;
  opacity: 0;
  z-index: 0;
}

.fadeOut {
  opacity: 0%;
}

.testimonialQuote {
  @include pStyles;
}

.testimonialName {
  text-align: end;
  font-size: 20px;
  font-weight: 600;
  font-family: $fontFamilyHeaders;
  color: $primary;
}

@media only screen and (max-width: 500px) {
  .testimonials {
    height: calc(100% - 80px);
  }
}

.statsText {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .statsText {
    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
      padding: $vSpace 0;
    }
  }
}

.plainLink {
  color: $primary;
}

.statIcon {
  width: 150px;
  height: 150px;
  background-color: $primary;
  border-radius: 100%;
  margin-bottom: $vSpace;
  display: flex;
  align-items: center;

  svg {
    padding: 35px;
    width: calc(100% - 70px);
    height: calc(100% - 70px);

    path {
      fill: $white;
    }
  }
}

.hr {
  border: 1px solid $grayText;
}

.teamMember {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: calc($vSpace / 2);
}

.teamMemberPhoto {
  width: 150px;
  height: 200px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

@media only screen and (min-width: 1040px) {
  .teamMemberPhoto {
    width: 225px;
    height: 300px;
  }
}

.teamMemberName {
  font-size: 20px;
  font-weight: 600;
  color: $primary;
}

.teamMemberTitle {
  font-size: 18px;
  color: $grayText;
  font-weight: 500;
}

.linkIconInButton {
  width: 12px;
  margin-left: 5px;

  path {
    transition: fill 200ms ease;
    fill: $white;
  }
}
