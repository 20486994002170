@import "src/styles/vars.scss";

.button {
  color: $white;
  background-color: $primary;
  border-radius: 100px;
  border: 2px solid $primary;
  font-weight: map-get($headerWeights, "bold");
  transition: all 200ms;
  max-width: 500px;
  text-align: center;

  &:hover {
    background-color: $white;
    color: $primary;

    svg path {
      fill: $primary;
    }
  }
}

.buttonInner {
  padding: 12px 16px;
}

.outerPadding {
  padding: 12px 16px;
}

.link {
  text-decoration: none;
}

.naked {
  background-color: $white;
  color: $primary;

  &:hover {
    background-color: $primary;
    color: $white;

    svg path {
      fill: $white;
    }
  }
}

.square {
  border-radius: 10px;
}

.float {
  color: black;
  border: none;
  background-color: $white;
  border-radius: 15px;
  transition: all 200ms;

  &:hover {
    color: black;
    background-color: $white;
    box-shadow: 0px 5px 10px #00000070;
    transform: translateY(-8px);
  }
}
