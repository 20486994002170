@import "src/styles/vars.scss";

.section {
  display: flex;
  justify-content: center;
  width: calc(100% - (2 * $pageMargin));
  padding: $pageMargin;
}

.fullHeight {
  height: calc(100% - (2 * $pageMargin));
}

.topBreak {
  padding-top: 5 * $vSpace;
}

.bottomBreak {
  padding-bottom: 5 * $vSpace;
}

.sectionInner {
  width: 100%;
  max-width: 1440px;
}

.center {
  display: flex;
  justify-content: center;
}

.hero {
  max-width: 1800px;
}

.background-gray {
  background-color: $gray;
}

.background-primary {
  background-color: $primary;
}

.blocksTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $vSpace * 2;
}

.blocksThree {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $vSpace;
}

.blocksFour,
.blocksFourTwo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: $vSpace;
}

.blocksThird {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: $vSpace;
}

.footerCustom {
  grid-template-columns: 1fr 1.25fr 1fr auto;
}

@media only screen and (max-width: 700px) {
  .blocksTwo {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    gap: $vSpace;
  }

  .blocksThree {
    grid-template-columns: none;
    grid-template-rows: auto auto auto;
  }

  .blocksFour {
    grid-template-columns: none;
    grid-template-rows: auto auto auto auto;
  }

  .blocksThird {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }

  .blocksFourTwo {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .topBreak {
    padding-top: 2 * $vSpace;
  }

  .bottomBreak {
    padding-bottom: 2 * $vSpace;
  }
}

@media only screen and (max-width: 600px) {
  .noMarginMobile {
    width: 100%;
    padding: 0;
  }
}
