@import "src/styles/vars";

.form {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - (2 * $pageMargin));
  width: 650px;
  background-color: $gray;
  padding: $pageMargin;
  border-radius: 15px;

  .required {
    font-size: small;
    color: $red;
    font-style: italic;
    margin-left: 5px;
  }

  label {
    color: $primary;
    margin-top: $vSpace;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
  }

  input,
  textarea {
    padding: calc($vSpace / 2);
    border-radius: 5px 5px 0 0;
    outline: none;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    border-left: 2px solid $white;
    border-bottom: 2px solid $primary;
    font-family: $fontFamilyPrimary;
    font-size: 16px;
  }

  textarea {
    resize: none;
  }

  button {
    margin-top: $vSpace;
    cursor: pointer;
    width: 300px;
    border-radius: 5px;
    font-family: $fontFamilyPrimary;
    font-size: 16px;
  }
}

.submitting {
  input,
  textarea {
    color: $grayText;
    font-style: italic;
    background-color: white;
    cursor: not-allowed;
    border-bottom-color: $grayText;
  }

  button {
    color: $gray;
    background-color: $grayText;
    border-color: $grayText;
    cursor: not-allowed;

    &:hover {
      color: $gray;
      background-color: $grayText;
      border-color: $grayText;
    }
  }
}

@media only screen and (max-width: 600px) {
  .form {
    padding: calc(2 * $pageMargin) $pageMargin;
    border-radius: 0;

    button {
      width: 100%;
    }
  }
}
