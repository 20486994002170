@import "src/styles/vars.scss";

.main {
  width: 100%;
  border-bottom: 2px solid $primary;
  cursor: pointer;
  padding-bottom: 0px;
}

.mainOpen {
  padding-bottom: $vSpace;
}

.questionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question {
  padding-top: $vSpace;
  padding-bottom: $vSpace;
}

.question,
.plusMinus {
  font-size: 22px;
  font-weight: 600;
}

.plusMinus {
  transform: rotate(0deg);
  transition: transform 250ms ease;
  padding: $vSpace;
}

.plusMinusOpen {
  transform: rotate(225deg);
}

.answerWrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 250ms ease;
}

.answer {
  overflow: hidden;
  @include pStyles;
  color: $grayText;
}

.open {
  grid-template-rows: 1fr;
}

@media only screen and (max-width: 600px) {
  .question,
  .plusMinus {
    font-size: 18px;
  }

  .answer {
    font-size: 16px;
  }
}
