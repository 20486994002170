@import "src/styles/vars.scss";

.button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: $fontFamilyPrimary;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.textCaret {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.caretIcon {
  height: 14px;
  padding-left: 8px;
  transform: rotateX(0deg);
  transition: transform 200ms ease;

  path {
    fill: $primary;
  }
}

.caretIconOpen {
  transform: rotateX(180deg);
}

.dropdownContent {
  margin-top: $vSpace;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 5px 10px 0px #0000007a;
  z-index: 1;
  border-radius: 0 0px 10px 10px;
  border: 2px solid $primary;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.open {
  display: flex;
  flex-direction: column;
}

.item {
  padding: $vSpace;
  border-bottom: 2px solid $gray;
  background-color: $white;
  transition: background-color 200ms ease;
  width: calc(100% - (2 * $vSpace));
  min-width: 150px;

  &:hover {
    background-color: $gray;
  }
}

.item:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
}
