@import "src/styles/vars.scss";

.logo {
  font-size: 35px;
  text-decoration: none;
  color: $grayText;
  font-weight: map-get($headerWeights, "medium");
  line-height: 35px;
}

@media only screen and (max-width: 400px) {
  .logo {
    font-size: 30px;
  }
}

.white {
  color: $white;
}

.svg {
  height: 70px;
  width: 70px;
  margin-top: -25px;
  margin-right: -15px;
  margin-bottom: -25px;
  margin-left: 0px;
  path {
    fill: $primary;
  }
}

.whiteSvg {
  path {
    fill: $white;
  }
}
