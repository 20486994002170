@import "src/styles/vars.scss";

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: $white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 5px #00000040;
  // prevents filter css from overlapping header
  z-index: 1;
}

.main {
  flex-grow: 1;
}

.navDesktop,
.navMobile {
  gap: $vSpace;
  width: 100%;
  margin-top: $vSpace;
  margin-right: $pageMargin;
  margin-bottom: $vSpace;
  margin-left: $pageMargin;
  justify-content: space-between;
  align-items: center;
}

.navDesktop {
  display: none;
}

.navMobile {
  display: flex;
}

.back {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: black;
  opacity: 0%;
  z-index: 2;
  transition: all 300ms;
}

.backOpen {
  display: block;
  opacity: 50%;
}

.line {
  display: block;
  background-color: $white;
  height: 0.5px;
  width: 100%;
  margin: 0;
}

.lineClear {
  background-color: transparent;
}

.navSidebar {
  display: flex;
  flex-direction: column;
  gap: $vSpace;
  padding: $pageMargin;
  width: calc(100% - (2 * $pageMargin));
  position: fixed;
  background-color: $white;
  z-index: 3;
  height: 100%;
  overflow-y: auto;
  right: -110%;
  transition: all 300ms;

  .navSidebarTop {
    display: flex;
    justify-content: space-between;
  }

  .linkSidebar {
    color: $primary;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    margin-left: $pageMargin;
  }

  .lineSidebar {
    display: block;
    background-color: $white;
    height: 0.5px;
    width: 100%;
    margin: 5px 0;
  }

  .lineSidebar {
    background-color: $primary;
    margin: $vSpace 0;
  }

  .headerSidebar {
    font-weight: 400;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: $vSpace;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin-left: 2 * $pageMargin;

      .linkSidebar {
        margin-left: 0;
      }
    }
  }
}

.open {
  right: 0%;
}

@media only screen and (min-width: 850px) {
  .navDesktop {
    display: flex;
  }

  .navMobile {
    display: none;
  }

  .navSidebar {
    display: none;
  }

  .back {
    display: none;
  }

  .line {
    display: none;
  }
}

.navSvg,
.closeSvg {
  width: 24px;
  cursor: pointer;

  path {
    fill: $primary;
  }
}

.links {
  display: flex;
  gap: $vSpace * 2;
}

.footerLinks {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: calc($vSpace);

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: calc($vSpace / 2);
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }
}

.linkFooter {
  color: $white;
  text-decoration: none;
}

.linkHeader {
  font-size: 18px;
  font-family: $fontFamilyHeaders;
}

.address {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 700px) {
  .linkHeader {
    margin-top: $pageMargin;
  }

  .footerLinks {
    align-items: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

// link styles from
// https://github.com/codrops/LineHoverStyles/blob/main/css/base.css
.link {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: $primary;
  text-decoration: none;
  font-size: map-get($headerSizes, "medium");
  font-weight: 600;

  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
  }

  &::before {
    content: "";
    /* show by default */
  }
}

.linkMetis {
  &::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
  }

  &:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
}

link:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
link:focus {
  /* Provide a fallback style for browsers
 that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

link:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
 that do support :focus-visible */
  background: transparent;
}

link:focus-visible {
  /* Draw a very noticeable focus style for
 keyboard-focus on browsers that do support
 :focus-visible */
  outline: 2px solid red;
  background: transparent;
}
